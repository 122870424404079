import React, {FC, ReactElement, useEffect, useState} from "react";

import "./tours_categories.css"
import Category from "../Category/category";

interface ToursCategoriesProps{
    categoriesEN: string[];
    categoriesPT: string[];
    selectedCategory: string;
    handleSelectedCategory: (category: string) => void;
    language: string;
}

const ToursCategories: FC<ToursCategoriesProps> = ({categoriesEN, categoriesPT, selectedCategory, handleSelectedCategory, language}) => {

    const categories = language == "EN" ? categoriesEN : categoriesPT;

    return (
        <div className="categories-container">
            {categories.map(category =>
                <Category 
                    key={category} 
                    name={category}
                    selectedCategory={selectedCategory}
                    handleSelectedCategory={handleSelectedCategory}
                    categoriesEN={categoriesEN}
                    categoriesPT={categoriesPT}
                />
            )}
        </div>
    );
}

export default ToursCategories;