import React, {FC, ReactElement, useEffect, useState} from "react";

import NavBar from "../../components/NavBar/navbar";
import ServicesScroll from "../../components/ServicesScroll/servicesScroll";
import Footer from "../../components/Footer/footer";
import { ServiceDetails } from "../../App"
import { FaYoutube } from "react-icons/fa"
import { useInView } from 'react-intersection-observer';
import AboutSection from "../../components/AboutSection/aboutSection";

import "./home.css"
import ServicesSlider from "../../components/ServicesSlider/servicesSlider";

interface HomeProps{
    language: string;
    handleLanguage: (value: string) => void;
    services: ServiceDetails[];
    selectedService: number;
    handleSelectedService: (value: number) => void;
    selectedPage: string; 
    handleSelectedPage: (value: string) => void
    handleMailSubjectChange: (value: string) => void;
}

const Home: FC<HomeProps> = ({ language, handleLanguage, services, selectedService, handleSelectedService, selectedPage, handleSelectedPage, handleMailSubjectChange }) => {

    const [ref, inView] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });

    useEffect(() => {
        handleSelectedPage("Home");
      }, []);

    return (
        <>
            <NavBar 
                language={language}
                handleLanguage={handleLanguage}
                selectedPage={selectedPage} 
                handleSelectedPage={handleSelectedPage}
            />
            <div className="services-slide">
                <ServicesSlider 
                    language={language} 
                    services={services} 
                    selectedPage={selectedPage} 
                    handleSelectedPage={handleSelectedPage} 
                    handleMailSubjectChange={handleMailSubjectChange}
                />
            </div>
            <div className="full-container">

                <ServicesScroll
                    language={language} 
                    services={services} 
                    selectedPage={selectedPage} 
                    handleSelectedPage={handleSelectedPage} 
                    handleMailSubjectChange={handleMailSubjectChange}
                />

            </div>
            <AboutSection 
                    language={language}
            />
            <Footer />
        </>
    );
}

export default Home;