import React, {FC, ReactElement, useEffect, useState} from "react";
import { ToursDetails } from "../../App";
import { Link } from "react-router-dom";
import "./tour_banner.css"

interface TourBannerProps{
    language: string;
    tour: ToursDetails;
    handleSelectedPage: (value: string) => void;
    handleMailSubjectChange: (value: string) => void;
}

const TourBanner: FC<TourBannerProps> = ({language, tour, handleSelectedPage, handleMailSubjectChange}) => {

    const onContactClick = () => {
        handleMailSubjectChange(tour.emailSubject ? tour.emailSubject : tour.title);
        handleSelectedPage("Contact");
    }
    
    return (
        <div className={`tour-banner tour-banner-id${tour.id}`} >
            <span className="tour-banner__title">{tour.title}</span>
            <i className={tour.country === "Brasil" ? "brasil-icon tour-banner__icon" : "portugal-icon tour-banner__icon"}></i>
        
            <Link to="/contact">
                <button
                    className="btn-language tour-banner__btn"
                    onClick={onContactClick}
                >
                    {language === "EN" ? "Contact us" : "Contacte-nos"}
                </button>
            </Link>
        </div>
    );
}

export default TourBanner;
