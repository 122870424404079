import React, { FC, ReactElement } from "react";

import "./button.css"
import { Link } from "react-router-dom";

interface ButtonProps{
    text: string;
    selectedPage: string;
    handleSelectedPage: (value: string) => void;
    subject?: string;
    handleMailSubjectChange?: (value: string) => void;
}

const Button: FC<ButtonProps> = ({ text, selectedPage, handleSelectedPage, subject, handleMailSubjectChange }) => {

    return (
        <>
            <Link to={`/${selectedPage.toLowerCase()}`}>
                <button 
                    className={selectedPage === "Contact" ? "btn animateTop" : "btn"}
                    onClick={() => {
                        if (handleMailSubjectChange && subject) {
                            handleMailSubjectChange(subject);
                        }
                        
                        handleSelectedPage(selectedPage);
                    }}
                >
                {text}
                </button>
            </Link>
        </>
    );

}

export default Button;