import React, {FC, ReactElement, useEffect, useState} from "react";

import NavBar from "../../components/NavBar/navbar";
import Footer from "../../components/Footer/footer";

import ToursCategories from "../../components/ToursCategories/tours_categories";
import TourBanner from "../../components/TourBanner/tour_banner";

import { ToursDetails } from "../../App";

import "./tours.css"

interface ToursProps{
    language: string;
    handleLanguage: (value: string) => void;
    selectedPage: string
    handleSelectedPage: (value: string) => void;
    tours: ToursDetails[];
    handleMailSubjectChange: (value: string) => void;
}

const Tours: FC<ToursProps> = ({ language, handleLanguage, selectedPage, handleSelectedPage, tours, handleMailSubjectChange}) => {

    const categoriesEN = [
        "Motorcycle",
        "Bikes",
        "Hiking",
        "Jeeps",
        "Cars",
    ];

    const categoriesPT = [
        "Moto",
        "Bicicletas",
        "Caminhada",
        "Jipes",
        "Carros",
    ];

    const [selectedCategory, setSelectedCategory] = useState<string>(categoriesEN[0]); 

    const handleSelectedCategory = (category: string) => {
        setSelectedCategory(category);
    }

    useEffect(() => {
        handleSelectedPage("Tours");
      }, []);

    return (
        <>
            <NavBar 
                language={language}
                handleLanguage={handleLanguage}
                selectedPage={selectedPage} 
                handleSelectedPage={handleSelectedPage}
            />

            <div className="tours-container">
                <div className="categories-menu">
                    <span className="tours-title">{language === "EN" ? 'Categories': 'Categorias'}</span>

                    <ToursCategories 
                        categoriesEN={categoriesEN}
                        categoriesPT={categoriesPT}
                        selectedCategory={selectedCategory}
                        handleSelectedCategory={handleSelectedCategory}
                        language={language}
                    />
                </div>

                <div className="tours-container__banners-container">
                    {tours.filter(tour => tour.category === selectedCategory).length > 0 ? (
                        tours
                            .filter(tour => tour.category === selectedCategory)
                            .map(tour => 
                                <TourBanner 
                                    key={tour.id}
                                    language={language}
                                    tour={tour}
                                    handleSelectedPage={handleSelectedPage}
                                    handleMailSubjectChange={handleMailSubjectChange}
                                />
                            )
                    ) : (
                        <div className="tours-container__banners-container--empty__container">
                            <p className="tours-container__banners-container--empty">
                                {language === "EN" ? "Coming Soon" : "Em breve"}
                            </p>
                            <span className="tours-container__banners-container--empty-info">
                                {language === "EN" ? "We are working on it, feel free to contact us about new opportunities!" : "Estamos a trabalhar nisto, sinta-se à vontade para nos contactar sobre novas oportunidades!"}
                            </span>
                        </div>
                    )}
                </div>

            </div>

            <Footer />
        </>
    );
}

export default Tours;