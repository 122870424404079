import React, { FC, useEffect, useState } from "react";
import Story from "../../components/Story/story";
import "./timeline.css";

import serraSul1 from '../../images/tours/brasil/bikes/CircuitoSerraSulFluminense/1.jpg';
import serraSul2 from '../../images/tours/brasil/bikes/CircuitoSerraSulFluminense/2.jpg';
import serraSul3 from '../../images/tours/brasil/bikes/CircuitoSerraSulFluminense/3.jpg';
import serraSul4 from '../../images/tours/brasil/bikes/CircuitoSerraSulFluminense/4.jpg';
import serraSul5 from '../../images/tours/brasil/bikes/CircuitoSerraSulFluminense/5.jpg';

import serraMar1 from '../../images/tours/brasil/motos/CircuitoSerraMar/1.jpg';
import serraMar2 from '../../images/tours/brasil/motos/CircuitoSerraMar/2.jpg';
import serraMar3 from '../../images/tours/brasil/motos/CircuitoSerraMar/3.jpg';
import serraMar4 from '../../images/tours/brasil/motos/CircuitoSerraMar/4.jpg';
import serraMar5 from '../../images/tours/brasil/motos/CircuitoSerraMar/5.jpg';

interface TimeLineProps {}

const TimeLine: FC<TimeLineProps> = ({}) => {
    /*const dates = [
        { id: 1, date: "1 Janeiro 2022", position: "0%" },
        { id: 2, date: "1 Janeiro 2022", position: "25%" },
        { id: 3, date: "1 Janeiro 2022", position: "50%" },
        { id: 4, date: "1 Janeiro 2022", position: "75%" },
        { id: 5, date: "1 Janeiro 2022", position: "99%" }
    ];

    const [scrollPercentage, setScrollPercentage] = useState<number>(0);

    const handleScroll = () => {
        const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrollPosition = window.scrollY;
        const scrolled = Math.min((scrollPosition / scrollHeight) * 100, 100);
        setScrollPercentage(scrolled);
    };

    useEffect(() => {
        const handleScrollWithRaf = () => {
            requestAnimationFrame(handleScroll);
        };

        window.addEventListener("scroll", handleScrollWithRaf);
        return () => window.removeEventListener("scroll", handleScrollWithRaf);
    }, []);

    return (
        <div className="timeline-container">
            <div className="timeline" style={{ height: `${scrollPercentage}%` }}></div>

            {dates.map((date) => (
                <div key={date.id} className="timeline-point" style={{ top: date.position }}>
                    <span>
                        {date.date} <br />
                        <span className="story-title">
                            <br />
                            Rota do Vinho 2024
                        </span>
                    </span>
                    <Story />
                </div>
            ))}
        </div>
    );*/

    const serraSulImages: any = [serraSul1, serraSul2, serraSul3, serraSul4, serraSul5];
    const serraMarImages: any = [serraMar1, serraMar2, serraMar3, serraMar4];

    return (
        <div className="timeline-containerV2">
            <div className="story-divV2">
                <span className="story-titleV2">
                    <br />
                    Circuito Serra Sul Fluminense
                </span>
                <Story 
                    images={serraSulImages}
                />
            </div>
            <div className="story-divV2">
                <span className="story-titleV2">
                    <br />
                    Circuito Serra Mar
                </span>
                <Story 
                    images={serraMarImages}
                />
            </div>
        </div>
    );
};

export default TimeLine;
