import React, {FC, ReactElement, useEffect} from "react";

import NavBar from "../../components/NavBar/navbar";
import Footer from "../../components/Footer/footer";
import AboutSection from "../../components/AboutSection/aboutSection";

import "./about.css"

interface AboutProps{
    language: string;
    handleLanguage: (value: string) => void;
    selectedPage: string;
    handleSelectedPage: (value: string) => void;
}

const About: FC<AboutProps> = ({ language, handleLanguage, selectedPage, handleSelectedPage }) => {

    useEffect(() => {
        handleSelectedPage("About");
      }, []);

    return (
        <>
            <NavBar 
                language={language} 
                handleLanguage={handleLanguage} 
                selectedPage={selectedPage} 
                handleSelectedPage={handleSelectedPage}
            />
            {/*<h1>About</h1>*/}
            <AboutSection language={language}/>
            <Footer />
        </>
    );
}

export default About;