import React, {FC, ReactElement, useEffect, useState} from "react";
import { Carousel } from 'react-responsive-carousel';

import "./story.css"

interface StoryProps{
    images: string[];
}

const Story: FC<StoryProps> = ({images}) => {

    const settings = {
        infiniteLoop: true,
        interval: 2000,
        autoPlay: true,
        showArrows: false,
        swipable: true,
        emulateTouch: true,
        showIndicators: false,
        showThumbs: false,
        showStatus: false,
    }


    return (
        <div className="storyV2">
            <Carousel {...settings}>
            {images.map((image, index) => (
                <div key={index}>
                    <img src={image} alt={`image ${index + 1}`} className="carousel-image" />
                </div>
            ))}
            </Carousel>
        </div>
    );
}

export default Story;