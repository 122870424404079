import React, { FC, ReactElement, useState } from "react";
import { Transition } from 'react-transition-group';

import { ServiceDetails } from "../../App";
import "./serviceModal.css"
import Button from "../Button/button";

interface ServiceModalProps {
    isOpen: boolean;
    onClose: () => void;
    serviceClicked: ServiceDetails | null;
    language: string;
    selectedPage: string; 
    handleSelectedPage: (value: string) => void
    handleMailSubjectChange?: (value: string) => void;
  }

const ServiceModal: FC<ServiceModalProps> = ({ isOpen, onClose, serviceClicked, language, selectedPage, handleSelectedPage, handleMailSubjectChange }) => {

    const duration = 300; // Duração da animação em milissegundos
    const overlay_duration = 500;

    return (
      language === "EN" ? (
        <Transition in={isOpen} timeout={duration} unmountOnExit>
          {(state) => (
            <div className={`modal-overlay modal-${state}`} onClick={onClose} 
              style={{
                  transition: `transform ${overlay_duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
                  transform: `translateY(${state === "exited" ? "100%" : "0"})`,
                  opacity: state === "exited" ? 0 : 1,
              }}>
              <div className={`modal modal-${state}`} onClick={(e) => e.stopPropagation()}>
                
                <div className="modal-close">
                  <button className="modal-close-btn" onClick={onClose}>
                    x
                  </button>
                </div>
                  
                
                <div className="content">
                  <div className="left-div">
                    <img src={serviceClicked?.image} alt={serviceClicked?.serviceTitleEN} />
                  </div>
                  <div className="right-div">
                    <div className="content-text">
                      <h1>{serviceClicked?.serviceTitleEN}</h1>
                      <p>{serviceClicked?.detailedDescriptionEN}</p>
                    </div>
                      

                    {serviceClicked && (
                      <Button 
                        text={"Contact Us"} 
                        selectedPage={"Contact"} 
                        handleSelectedPage={handleSelectedPage}
                        subject={serviceClicked?.serviceTitleEN}
                        handleMailSubjectChange={handleMailSubjectChange}
                      />
                    )}
                  </div>
                </div>
                
              </div>
            </div>
          )}
        </Transition>
      ) : (
        <Transition in={isOpen} timeout={duration} unmountOnExit>
          {(state) => (
            <div className={`modal-overlay modal-${state}`} onClick={onClose} 
              style={{
                  transition: `transform ${overlay_duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
                  transform: `translateY(${state === "exited" ? "100%" : "0"})`,
                  opacity: state === "exited" ? 0 : 1,
              }}>
              <div className={`modal modal-${state}`} onClick={(e) => e.stopPropagation()}>
                
                <div className="modal-close">
                  <button className="modal-close-btn" onClick={onClose}>
                    x
                  </button>
                </div>
                
                <div className="content">
                  <div className="left-div">
                    <img src={serviceClicked?.image} alt={serviceClicked?.serviceTitle} />
                  </div>
                  <div className="right-div">
                    <div className="content-tex">
                      <h1>{serviceClicked?.serviceTitle}</h1>
                      <p>{serviceClicked?.detailedDescription}</p>
                    </div>

                      {serviceClicked && (
                        <Button 
                          text={"Contacte-nos"} 
                          selectedPage={"Contact"} 
                          handleSelectedPage={handleSelectedPage}
                          subject={serviceClicked?.serviceTitle}
                          handleMailSubjectChange={handleMailSubjectChange}
                        />
                      )}
                  </div>
                </div>

              </div>
            </div>
          )}
        </Transition>
      )
    )
}

export default ServiceModal;
