import React, {FC, ReactElement, useEffect} from "react";

import logo from "../../images/MotorFest.png";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa"
import "./footer.css";

interface FooterProps{
    selectedPage?: string;
}

const Footer: FC<FooterProps> = ({ selectedPage }) => {

    useEffect(() => {
        console.log(selectedPage)
    })

    return (

        <div className={selectedPage === "Contact" ? "flex-container-footer-noMargin" : "flex-container-footer"}>
            <img src={logo} alt="logo" className="" height={120}/>

            <footer>
                <div className="wrapper">
                    {/*<div className="schedule">
                        <h3>Schedule</h3>
                        <p>Weekdays : 8:00am - 10:00pm</p>
                        <p>Saturday: 8:00am - 2:00pm</p>
                    </div>*/}

                    <div className="contacts">
                        <h3>Contacts</h3>
                        <p>info@motorfest.pt</p>
                        <p>+351 966 716 113</p>
                        <p>+55 24 98134-2833</p>
                    </div>

                    <div className="social">
                        <h3>Social</h3>
                        <div className="icons-container">
                            <a href="https://www.instagram.com/motorfest.pt/"><FaInstagram size={22} className="icon" /></a>
                            <a href="https://www.facebook.com/motorfest.pt"><FaFacebookF size={22} className="icon" /></a>
                            <a href="https://wa.me/+351966716113"><FaWhatsapp size={22} className="icon" /></a>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
       
    );
}

export default Footer;