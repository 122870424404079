import React, {ChangeEvent, FC, FormEvent, ReactElement, useEffect, useRef, useState} from "react";
import axios from "axios";

import NavBar from "../../components/NavBar/navbar";
import Map from "../../components/Map/map";
import Footer from "../../components/Footer/footer";
import { useInView } from "react-intersection-observer";
import emailjs from '@emailjs/browser';

import "./contact.css"

interface ContactProps{
    language: string;
    handleLanguage: (value: string) => void;
    selectedPage: string;
    handleSelectedPage: (value: string) => void;
    subject?: string;
    handleMailSubjectChange: (value: string) => void;
}

interface EmailData{
    name: string;
    email: string;
    to: string;
    subject: string;
    message: string;
}

const Contact: FC<ContactProps> = ({ language, handleLanguage, selectedPage, handleSelectedPage, subject, handleMailSubjectChange }) => {

    /*const nameRef = useRef<HTMLFormElement>();
    const emailRef = useRef<HTMLFormElement>();
    const subjectRef = useRef<HTMLFormElement>();
    const messageRef = useRef<HTMLFormElement>();*/
    

    const [formActive, setFormActive] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState(subject || ""); // Onde "subject" é o valor inicial
    const [emailData, setEmailData] = useState<EmailData>({
        name: "",
        email: "",
        to: "info@motorfest.pt", //TROCAR EMAIL
        subject: inputValue,
        message: "",
    });

    const [ref, inView] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref1, inView1] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });

    const formRef = useRef<HTMLFormElement | null>(null);

    useEffect(() => {
        handleSelectedPage("Contact");
    }, []);

    useEffect(() => emailjs.init("1rbsFHJSztc2v40_P"), []);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
      
        setEmailData((previousData) => ({
          ...previousData,
          [name]: value,
        }));

        //console.log(emailData);
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        /*try {
            // Envie o e-mail para o servidor usando axios
            await axios.post("http://motorfest.pt/send-email", emailData);
            console.log("E-mail enviado com sucesso!");
            alert("E-mail enviado com sucesso!");
        
            // Limpe os campos do formulário após o envio
            setEmailData({
                name: "",
                email: "",
                to: "info@motorfest.pt",
                subject: "",
                message: "",
            });
        } catch (error) {
            console.error("Erro ao enviar o e-mail:", error);
            alert("Ocorreu um erro ao enviar o e-mail.");
        }*/

        await emailjs.send('service_u3zh1ld', 'template_jrilgtv', {
            from_name: emailData.name,
            from_email: emailData.email,
            subject: emailData.subject,
            message: emailData.message,

        })
        .then((result: { text: any; }) => {
            alert("Email enviado com sucesso!");
            //console.log(result.text);
            // Reset the form using the ref
            if (formRef.current) {
                formRef.current.reset();
            }
        }, (error: { text: any; }) => {
            console.log(error.text);
        });

    };

    return (

        language === "EN" ? (
            <>
                <NavBar 
                    language={language}
                    handleLanguage={handleLanguage}
                    selectedPage={selectedPage} 
                    handleSelectedPage={handleSelectedPage}
                />
                {/*<div className={inView1 ? "map-container animateFade" : "map-container"} ref={ref1}>
                    <p id="title-contact">Contact Us</p>
                    <Map />
                </div>*/}
                <div className="contact-container">
                    <div className={inView ? "form-container animateTop" : "form-container"} ref={ref}>
                        <form action="POST" onSubmit={handleSubmit} ref={formRef}>
                            <h1 style={{color: "white", textAlign: "left"}}>Leave a Message</h1>
                            <input 
                                type="text" 
                                name="name" 
                                id=""
                                placeholder="Name"
                                value={emailData.name} 
                                onChange={handleChange}
                                //ref={nameRef}
                                required
                            />
                            <input 
                                type="text" 
                                name="email" 
                                id="" 
                                placeholder="Email"
                                value={emailData.email} 
                                onChange={handleChange}
                                //ref={emailRef}
                                required
                            />
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <input 
                                    type="text" 
                                    name="subject" 
                                    id="" 
                                    placeholder="Subject"
                                    value={inputValue}
                                    style={subject ? {fontWeight: "bold"} : {}}
                                    onChange={(e) => {
                                        setInputValue(e.target.value)
                                        handleChange(e);
                                    }}
                                    //ref={subjectRef}
                                    required
                                />
                                {inputValue && (
                                    <span 
                                        className="close"
                                        onClick={() => {
                                            handleMailSubjectChange("");
                                            setInputValue(""); // Limpa o valor do input
                                            // E qualquer outra ação necessária
                                            setEmailData((previousData) => ({
                                                ...previousData,
                                                subject: "",
                                            }));
                                        }}
                                    >
                                        x
                                    </span>
                                )}
                            </div>
                            <textarea 
                                name="message" 
                                id=""
                                placeholder="Message"
                                onChange={(e) => {
                                    setFormActive(true);
                                    handleChange(e);
                                }}
                                //ref={messageRef}
                                required
                            >
                            </textarea>
                            <input 
                                type="submit" 
                                value="Send"
                                disabled={!formActive}
                            />
                        </form>
                    </div>
                    <div className={inView ? "contacts-info-container animateTop" : "contacts-info-container"}>
                        <h1 style={{color: "white", textAlign:"left"}}>Need Help?</h1>
                        <div className="box">
                            <h3>Info</h3>
                            <p>info@motorfest.pt</p>
                        </div>
                        <div className="box">
                            <h3>Álvaro Benevides</h3>
                            <p>alvaro@motorfest.pt</p>
                        </div>
                        <div className="box">
                            <h3>Cicero Gerhardt</h3>
                            <p>cicero@motorfest.pt</p>
                        </div>
                    </div>
                </div>
                
                <Footer selectedPage={selectedPage}/>
            </>
        ) : (
            <>
                <NavBar 
                    language={language}
                    handleLanguage={handleLanguage}
                    selectedPage={selectedPage} 
                    handleSelectedPage={handleSelectedPage}
                />
                {/*<div className={inView1 ? "map-container animateFade" : "map-container"} ref={ref1}>
                    <p id="title-contact">Contacte-nos</p>
                    <Map />
                </div>*/}
                <div className="contact-container">
                    <div className={inView ? "form-container animateTop" : "form-container"} ref={ref}>
                        <form action="" onSubmit={handleSubmit} ref={formRef}>
                            <h1 style={{color: "white", textAlign: "left"}}>Mande Mensagem</h1>
                            <input 
                                type="text" 
                                name="name" 
                                id=""
                                placeholder="Nome"
                                value={emailData.name} 
                                onChange={handleChange} 
                                required
                            />
                            <input 
                                type="text" 
                                name="email" 
                                id="" 
                                placeholder="Email"
                                value={emailData.email} 
                                onChange={handleChange}
                                required
                            />
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <input 
                                    type="text" 
                                    name="subject" 
                                    id="" 
                                    placeholder="Assunto"
                                    value={inputValue}
                                    style={subject ? {fontWeight: "bold"} : {}}
                                    onChange={(e) => {
                                        setInputValue(e.target.value)
                                        handleChange(e);
                                    }}
                                    required
                                />
                                {inputValue && (
                                    <span 
                                        className="close"
                                        onClick={() => {
                                            handleMailSubjectChange("");
                                            setInputValue(""); // Limpa o valor do input
                                            // E qualquer outra ação necessária
                                            setEmailData((previousData) => ({
                                                ...previousData,
                                                subject: "",
                                            }));
                                        }}
                                    >
                                        x
                                    </span>
                                )}
                            </div>
                            <textarea 
                                name="content" 
                                id=""
                                placeholder="Mensagem"
                                onChange={(e) => {
                                    setFormActive(true);
                                    handleChange(e);
                                }}
                                required
                            >
                            </textarea>
                            <input 
                                type="submit" 
                                value="Enviar"
                                disabled={!formActive}
                            />
                        </form>
                    </div>
                    <div className={inView ? "contacts-info-container animateTop" : "contacts-info-container"}>
                        <h1 style={{color: "white", textAlign:"left"}}>Precisa de ajuda?</h1>
                        <div className="box">
                            <h3>Info</h3>
                            <p>info@hmotorfest.pt</p>
                        </div>
                        <div className="box">
                            <h3>Álvaro Benevides</h3>
                            <p>alvaro@motorfest.pt</p>
                        </div>
                        <div className="box">
                            <h3>Cicero Gerhardt</h3>
                            <p>cicero@motorfest.pt</p>
                        </div>
                    </div>
                </div>
                
                <Footer selectedPage={selectedPage}/>
            </>
        )
        
    );
}

export default Contact;