import React, { FC, ReactElement } from "react";

import "./languageSelector.css"

interface LanguageSelectorProps{
    language: string;
    handleLanguage: (value: string) => void;
    selectedPage: string;
    menuToggled: boolean;
}

const LanguageSelector: FC<LanguageSelectorProps> = ({ language, handleLanguage, selectedPage, menuToggled }) => {

    const isHomePage = selectedPage === "Home" ? true : false;

    return (

        !menuToggled ? (
            <div className="languageSelector">
                <button
                    className={isHomePage ? (language === "EN" ? "btn-language-selected-white" : "btn-language") : (language === "EN" ? "btn-language-selected-black" : "btn-language")}
                    onClick={() => handleLanguage("EN")}
                >
                    EN
                </button>
                <button
                    className={isHomePage ? (language === "PT" ? "btn-language-selected-white" : "btn-language") : (language === "PT" ? "btn-language-selected-black" : "btn-language")} 
                    onClick={() => handleLanguage("PT")}
                >
                    PT
                </button>
            </div>
        ) : (
            <div className="languageSelector-menu">
                <button
                    className={isHomePage ? (language === "EN" ? "btn-language-selected-black" : "btn-language") : (language === "EN" ? "btn-language-selected-black" : "btn-language")}
                    onClick={() => handleLanguage("EN")}
                >
                    EN
                </button>
                <button
                    className={isHomePage ? (language === "PT" ? "btn-language-selected-black" : "btn-language") : (language === "PT" ? "btn-language-selected-black" : "btn-language")} 
                    onClick={() => handleLanguage("PT")}
                >
                    PT
                </button>
            </div>
        )
        
    );
}

export default LanguageSelector;