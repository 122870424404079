import React, { FC, useEffect, useState } from "react";

import rental_car from "../../../images/services/microServices/rental_car.png"

import "./serviceWidget.css";
import { Link } from "react-router-dom";
import { ServiceDetails } from "../../../App";

interface WidgetProps{
  id: number;
  isFullWidth?: boolean;
  language: string;
  service: ServiceDetails;
}

const ServiceWidget: FC<WidgetProps> = ({ id, isFullWidth, language, service }) => {

  const [isMouseHover, setIsMouseHover] = useState<boolean>(false);
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsMouseHover(true);
    setIsButtonVisible(true); // Botão visível quando o mouse está sobre o widget
  };

  const handleMouseLeave = () => {
    setIsMouseHover(false);
    setIsButtonVisible(false); // Botão invisível quando o mouse sai do widget
  };

  const widgetText = (service: ServiceDetails, id: number) => {

    if(language === "EN"){
      switch(service.id){
        case 5: {
          if(id === 0){
            return ["Car Rental", "Convenient rental of cars for your travel needs"];
          }

          if(id === 1){
            return ["Motorcycle Rental", "Explore the open road with our motorcycle rental services"];
          }

          if(id === 2){
            return ["Motorhome Rental", "Experience the freedom of the road in a motorhome rental"];
          }

          if(id === 3){
            return ["Bicycle Rental", "Enjoy a leisurely ride with our bicycle rental options"];
          }
          break;
        }
          
        case 6: {
          if(id === 0){
            return ["Vehicles Insurance", "Coverage for your cars and other vehicles"];
          }

          if(id === 1){
            return ["Properties Insurance", "Coverage for your real estate properties and assets"];
          }

          if(id === 2){
            return ["Travel Insurance", "Peace of mind while you explore the world"];
          }

          if(id === 3){
            return["Travel Insurance", "Peace of mind while you explore the world"];
          }
          break;
        }
          
        case 9: {
          if(id === 0){
            return ["Car Guided Trips", "Explore amazing destinations with our guided car trips"]
          }

          if(id === 1){
            return ["Motorcycle Trips", "Experience thrilling adventures on guided motorcycle trips"];
          }

          if(id === 2){
            return ["Bicycle Trips", "Enjoy scenic tours and bike rides with our guided bicycle trips."];
          }

          if(id === 3){
            return ["Hiking Trips", "Discover breathtaking landscapes on foot with our guided hiking trips."];
          }          
          break;
        }

        default: 
          return ["Title", "Descrition"];
      }
    }else{
      switch(service.id){
        case 5: {
          if(id === 0){
            return ["Aluguel de Carros", "Aluguel conveniente de carros para suas necessidades de viagem"];
          }

          if(id === 1){
            return [ "Aluguel de Motos", "Explore a estrada aberta com nossos serviços de aluguel de motocicletas"];
          }

          if(id === 2){
            return ["Aluguel de Motorhomes","Experimente a liberdade da estrada em um aluguel de motorhome"];
          }

          if(id === 3){
            return ["Aluguel de Bicicletas", "Desfrute de um passeio tranquilo com nossas opções de aluguel de bicicletas"];
          }
          break;
        }
          
        case 6: {
          if(id === 0){
            return ["Seguro de Veículos", "Cobertura para seus carros e outros veículos"]
          }

          if(id === 1){
            return ["Seguro de Propriedades", "Cobertura para suas propriedades imobiliárias e ativos"]
          }

          if(id === 2){
            return ["Seguro de Vida", "Proteção para o futuro de sua família"];
          }

          if(id === 3){
            return ["Seguro de Viagem", "Tranquilidade enquanto você explora o mundo"];
          }
          break;
        }
          
        case 9: {
          if(id === 0){
            return ["Viagens Guiadas de Carro", "Explore destinos incríveis com nossas viagens de carro guiadas"]
          }

          if(id === 1){
            return ["Viagens de Motociclo", "Viva aventuras emocionantes em viagens de motocicleta guiadas"];
          }

          if(id === 2){
            return ["Viagens de Bicicleta", "Desfrute de passeios cênicos e pedaladas com nossas viagens de bicicleta guiadas."];
          }

          if(id === 3){
            return ["Caminhadas", "Descubra paisagens deslumbrantes a pé com as nossas caminhadas guiadas."];
          }          
          break;
        }

        default: 
          return ["Titulo", "Descrição"];
      }
    } 
  }

  return (

    language === "EN" ? (
      <div
        className={`${isFullWidth ? `widget-full-width widget-full-width-${service.id}-${id}` : `widget widget-${service.id}-${id}`} ${isMouseHover ? "hovered" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="widget-content">
          <div className="footer">
            <div className="text-container">
              <p className={isMouseHover ? "disappear" : ""} style={{marginBottom: "10px"}}><b>{widgetText(service, id)?.at(0)}</b></p>
              <p className={isMouseHover ? "disappear" : ""} style={windowWidth > 1500 ? {fontSize: "0.8rem"} : {fontSize: "0.7rem"}}>{widgetText(service, id)?.at(1)}</p>
            </div>
            <div
              className={`button-container ${
                isButtonVisible ? "appear-button" : "disappear-button"
              }`}
            >
              <Link to="/services" style={{textDecoration: "none", color: "rgb(185, 0, 0)"}}>
                  Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        className={`${isFullWidth ? `widget-full-width widget-full-width-${service.id}-${id}` :`widget widget-${service.id}-${id}`} ${isMouseHover ? "hovered" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="widget-content">
          <div className="footer">
            <div className="text-container">
              <p className={isMouseHover ? "disappear" : ""} style={{marginBottom: "10px"}}><b>{widgetText(service, id)?.at(0)}</b></p>
              <p className={isMouseHover ? "disappear" : ""} style={windowWidth > 1500 ? {fontSize: "0.8rem"} : {fontSize: "0.7rem"}}>{widgetText(service, id)?.at(1)}</p>
            </div>
            <div
              className={`button-container ${
                isButtonVisible ? "appear-button" : "disappear-button"
              }`}
            >
              <Link to="/services" style={{textDecoration: "none", color: "rgb(185, 0, 0)"}}>
                  Saiba mais
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
    
  );
};

export default ServiceWidget;
