import React, {FC, ReactElement, useEffect} from "react";

import NavBar from "../../components/NavBar/navbar";
import Footer from "../../components/Footer/footer";
import TimeLine from "../../components/TimeLine/timeline";
//import Story from "../../components/Story/story";

import "./stories.css"

interface StoriesProps{
    language: string;
    handleLanguage: (value: string) => void;
    selectedPage: string
    handleSelectedPage: (value: string) => void;
}

const Stories: FC<StoriesProps> = ({ language, handleLanguage, selectedPage, handleSelectedPage}) => {

    useEffect(() => {
        handleSelectedPage("Stories");
      }, []);

    return (
        <div className="stories-page">
            <NavBar 
                language={language}
                handleLanguage={handleLanguage}
                selectedPage={selectedPage} 
                handleSelectedPage={handleSelectedPage}
            />

            {/*<div className="stories-container">
                <div className="stories-container__timeline-container">
                    <TimeLine/>
                </div>
            </div>*/}

            <TimeLine/>

            <Footer />
        </div>
    );
}

export default Stories;