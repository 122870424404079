import React, { FC, ReactElement, useEffect, useState } from "react";

import { IoNewspaperOutline } from "react-icons/io5"
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { IoDocumentsOutline } from 'react-icons/io5';
import { PiGarage } from "react-icons/pi";
import { PiWrenchLight } from 'react-icons/pi';
import { IoCarOutline } from 'react-icons/io5';
import { FaRegHandshake } from 'react-icons/fa';
import { GiHorizonRoad } from "react-icons/gi";
import { GiCoins } from 'react-icons/gi';
import { BsHouseCheck } from 'react-icons/bs';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { useInView } from "react-intersection-observer";
import { ServiceDetails } from "../../App";
import { Link } from "react-router-dom";
import Button from "../Button/button";
import ServiceModal from "../ServiceModal/serviceModal";

import "./servicesSection.css";

interface ServiceSectionProps{
    language: string;
    services: ServiceDetails[];
    selectedPage: string;
    handleSelectedPage: (value: string) => void;
    handleMailSubjectChange?: (value: string) => void;
}

const ServicesSection: FC<ServiceSectionProps> = ({ language, services, selectedPage, handleSelectedPage, handleMailSubjectChange }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isAnimatingOut, setIsAnimatingOut] = useState(false); // Estado para controlar a animação
    const itemsPerPage = 4;
    const [servicesGroup, setServicesGroup] = useState<ServiceDetails[]>(services.slice(0, 4));
    const [serviceClicked, setServiceClicked] = useState<ServiceDetails | null>(null);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleCurrentPage = (value: number) => {
        if (value !== currentPage) {
            setIsAnimatingOut(true);
            setTimeout(() => {
                setCurrentPage(value);
            }, 250);
        }
    }

    const handleServicesDisplayed = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const displayedServices = services.slice(startIndex, endIndex);

        setTimeout(() => {
            setIsAnimatingOut(false); // Desative a animação de saída
            setServicesGroup(displayedServices); // Atualize os serviços com os novos elementos
        }, 500); // Ajuste o tempo de acordo com a duração da animação

    }

    const [ref, inView] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });

    const getServiceIcon = (index: number) => {
        switch(index){
            case 0:
                return <RiMoneyDollarCircleLine size={80}/>
            case 1:
                return <IoDocumentsOutline size={80}/>
            case 2:
                return <PiGarage size={80}/>
            case 3:
                return <PiWrenchLight size={80}/>
            case 4:
                return <IoCarOutline size={80}/>
            case 5:
                return <FaRegHandshake size={80}/>
            case 6:
                return <IoNewspaperOutline size={80}/>
            case 7:
                return <GiCoins size={80}/>
            case 8:
                return <GiHorizonRoad size={80}/>
            case 9:
                return <BsHouseCheck size={80}/>
        }
    }

    useEffect(() => {
        handleServicesDisplayed();
    }, [currentPage, services])

    return(

        language === "EN" ? (
            <div className={inView ? "section-container animateFade" : "section-container"} ref={ref}>

                <div className="services-nav">
                    <p id="title-services">Services</p>
                    <div></div>
                    <Button 
                        text={"Custom Service"} 
                        selectedPage={"Contact"} 
                        handleSelectedPage={handleSelectedPage}
                        subject={"Serviço Personalizado"}
                        handleMailSubjectChange={handleMailSubjectChange}
                    />
                </div>

                <div className="cards-wrapper">
                    <div className="arrows">
                        <button
                            onClick={() => {
                                if (currentPage > 1) {
                                    handleCurrentPage(currentPage - 1);
                                }
                            }}
                        >
                            <FaArrowLeft />
                        </button>
                    </div>
                    <div className={currentPage === 3 ? `cards-container-twoElements ${isAnimatingOut ? 'fade-out' : 'fade-in'}` : `cards-container ${isAnimatingOut ? 'fade-out' : 'fade-in'}`}>
                        {currentPage < 3 && 
                            servicesGroup
                            .map((service, index) => (
                                <div 
                                    key={index} 
                                    className="service-card"
                                    title="Click to see more"
                                    onClick={() => {
                                        /*if (handleMailSubjectChange) {
                                            handleMailSubjectChange(service.serviceTitleEN);
                                        }
                                        
                                        handleSelectedPage("Contact");*/
                                        setServiceClicked(service);
                                        openModal();
                                    }}
                                >
                                    <div className="service-icon">
                                        {getServiceIcon((currentPage - 1) * 4 + index)}
                                    </div>
                                    <div className="service-title">
                                        {service.serviceTitleEN}
                                    </div>
                                    <div className="service-description">
                                        {service.serviceDescriptionEN}
                                    </div>

                                </div>
                            ))
                        }
                        {currentPage === 3 && 
                            servicesGroup.filter((service) => (service.id < 11))
                            .map((service, index) => (
                                <div 
                                    key={index} 
                                    className="service-card"
                                    onClick={() => {
                                        /*if (handleMailSubjectChange) {
                                            handleMailSubjectChange(service.serviceTitleEN);
                                        }
                                        
                                        handleSelectedPage("Contact");*/
                                        setServiceClicked(service);
                                        openModal();
                                    }}
                                >
                                    <div className="service-icon">
                                        {getServiceIcon((currentPage - 1) * 4 + index)}
                                    </div>
                                    <div className="service-title">
                                        {service.serviceTitleEN}
                                    </div>
                                    <div className="service-description">
                                        {service.serviceDescriptionEN}
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                    <div className="arrows">
                        <button
                            onClick={() => {
                                if (currentPage < 3) {
                                    handleCurrentPage(currentPage + 1);
                                }
                            }}
                        >
                            <FaArrowRight />
                        </button>
                    </div>
                </div>

                <div className="pagination">
                    <button 
                        className={currentPage === 1 ? "selected" : ""}
                        onClick={() => handleCurrentPage(1)}
                    >
                        1
                    </button>
                    <button
                        className={currentPage === 2 ? "selected" : ""}
                        onClick={() => handleCurrentPage(2)}
                    >
                        2
                    </button>
                    <button
                        className={currentPage === 3 ? "selected" : ""}
                        onClick={() => handleCurrentPage(3)}
                    >
                        3
                    </button>
                </div>

                <ServiceModal 
                    isOpen={isModalOpen} 
                    onClose={closeModal}
                    serviceClicked={serviceClicked}
                    language={language}
                    selectedPage={selectedPage}
                    handleSelectedPage={handleSelectedPage}
                    handleMailSubjectChange={handleMailSubjectChange}
                />

                {selectedPage !== "Services" && (
                    <Button 
                        text={"Go to Services"} 
                        selectedPage={"Services"} 
                        handleSelectedPage={handleSelectedPage}
                    />
                )}

            </div>
        ) : (
            <div className={inView ? "section-container animateFade" : "section-container"} ref={ref}>

                <div className="services-nav">
                    <p id="title-services">Serviços</p>
                    <div></div>
                    <Button 
                        text={"Custom Service"} 
                        selectedPage={"Contact"} 
                        handleSelectedPage={handleSelectedPage}
                        subject={"Serviço Personalizado"}
                        handleMailSubjectChange={handleMailSubjectChange}
                    />
                </div>
                <div className={currentPage === 3 ? `cards-container-twoElements ${isAnimatingOut ? 'fade-out' : 'fade-in'}` : `cards-container ${isAnimatingOut ? 'fade-out' : 'fade-in'}`}>
                    {currentPage < 3 && 
                        servicesGroup
                        .map((service, index) => (
                            <div 
                                key={index} 
                                className="service-card"
                                title="Clique para ver mais"
                                onClick={() => {
                                    /*if (handleMailSubjectChange) {
                                        handleMailSubjectChange(service.serviceTitleEN);
                                    }
                                    
                                    handleSelectedPage("Contact");*/
                                    setServiceClicked(service);
                                    openModal();
                                }}
                            >
                                <div className="service-icon">
                                    {getServiceIcon((currentPage - 1) * 4 + index)}
                                </div>
                                <div className="service-title">
                                    {service.serviceTitle}
                                </div>
                                <div className="service-description">
                                    {service.serviceDescription}
                                </div>

                            </div>
                        ))
                    }
                    {currentPage === 3 && 
                        servicesGroup.filter((service) => (service.id < 11))
                        .map((service, index) => (
                            <div 
                                key={index} 
                                className="service-card"
                                onClick={() => {
                                    /*if (handleMailSubjectChange) {
                                        handleMailSubjectChange(service.serviceTitleEN);
                                    }
                                    
                                    handleSelectedPage("Contact");*/
                                    setServiceClicked(service);
                                    openModal();
                                }}
                            >
                                <div className="service-icon">
                                    {getServiceIcon((currentPage - 1) * 4 + index)}
                                </div>
                                <div className="service-title">
                                    {service.serviceTitle}
                                </div>
                                <div className="service-description">
                                    {service.serviceDescription}
                                </div>

                            </div>
                        ))
                    }
                </div>
                <div className="pagination">
                    <button 
                        className={currentPage === 1 ? "selected" : ""}
                        onClick={() => handleCurrentPage(1)}
                    >
                        1
                    </button>
                    <button
                        className={currentPage === 2 ? "selected" : ""}
                        onClick={() => handleCurrentPage(2)}
                    >
                        2
                    </button>
                    <button
                        className={currentPage === 3 ? "selected" : ""}
                        onClick={() => handleCurrentPage(3)}
                    >
                        3
                    </button>
                </div>

                <ServiceModal 
                    isOpen={isModalOpen} 
                    onClose={closeModal}
                    serviceClicked={serviceClicked}
                    language={language}
                    selectedPage={selectedPage}
                    handleSelectedPage={handleSelectedPage}
                    handleMailSubjectChange={handleMailSubjectChange}
                />

                {selectedPage !== "Services" && (
                    <Button 
                        text={"Go to Services"} 
                        selectedPage={"Services"} 
                        handleSelectedPage={handleSelectedPage}
                    />
                )}

            </div>
        )

    );

    /*return (

        language === "EN" ? (
            <div className={inView ? "section-container animateFade" : "section-container"} ref={ref}>

                <div className="services-nav">
                    <p id="title-services">Services</p>
                    <div></div>
                    <Button 
                        text={"Custom Service"} 
                        selectedPage={"Contact"} 
                        handleSelectedPage={handleSelectedPage}
                        subject={"Serviço Personalizado"}
                        handleMailSubjectChange={handleMailSubjectChange}
                    />
                </div>
                <div className="cards-container">
                    {services.filter((service) => service.id < 11)
                        .map((service, index) => (
                        <div 
                            key={index} 
                            className="service-card"
                            onClick={() => {
                                if (handleMailSubjectChange) {
                                    handleMailSubjectChange(service.serviceTitleEN);
                                }
                                
                                handleSelectedPage("Contact");
                                openModal();
                            }}
                        >
                            <div className="service-icon">
                                {getServiceIcon(index)}
                            </div>
                            <div className="service-title">
                                {service.serviceTitleEN}
                            </div>
                            <div className="service-description">
                                {service.serviceDescriptionEN}
                            </div>

                        </div>
                    ))}
                </div>
                <ServiceModal isOpen={isModalOpen} onClose={closeModal}/>

                {selectedPage !== "Services" && (
                    <Button 
                        text={"Go to Services"} 
                        selectedPage={"Services"} 
                        handleSelectedPage={handleSelectedPage}
                    />
                )}

            </div>
        ) : (
            <div className={inView ? "section-container animateFade" : "section-container"} ref={ref}>

                <div className="services-nav">
                    <p id="title-services">Serviços</p>
                    <div></div>
                    <Button 
                        text={"Serviço Personalizado"} 
                        selectedPage={"Contact"} 
                        handleSelectedPage={handleSelectedPage}
                        subject={"Serviço Personalizado"}
                        handleMailSubjectChange={handleMailSubjectChange}
                    />
                </div>
                <div className="cards-container">
                    {services.filter((service) => service.id < 11)
                        .map((service, index) => (
                        <div 
                            key={index} 
                            className="service-card"
                            onClick={() => {
                                if (handleMailSubjectChange) {
                                    handleMailSubjectChange(service.serviceTitle);
                                }
                                
                                handleSelectedPage("Contact");
                                openModal();
                            }}
                        >
                            <div className="service-icon">
                                {getServiceIcon(index)}
                            </div>
                            <div className="service-title">
                                {service.serviceTitle}
                            </div>
                            <div className="service-description">
                                {service.serviceDescription}
                            </div>

                        </div>
                    ))}
                </div>
                <ServiceModal isOpen={isModalOpen} onClose={closeModal}/>
            </div>
        )
        
    );*/
}

export default ServicesSection;