import React, {FC, ReactElement, useEffect} from "react";

import NavBar from "../../components/NavBar/navbar";
import Footer from "../../components/Footer/footer";
import ServicesSection from "../../components/ServicesSection/servicesSection";
import { ServiceDetails } from "../../App";

import "./services.css"

interface ServicesProps{
    language: string;
    handleLanguage: (value: string) => void;
    services: ServiceDetails[];
    selectedPage: string
    handleSelectedPage: (value: string) => void;
    handleMailSubjectChange: (value: string) => void;
}

const Services: FC<ServicesProps> = ({ language, handleLanguage, services, selectedPage, handleSelectedPage, handleMailSubjectChange }) => {

    useEffect(() => {
        handleSelectedPage("Services");
      }, []);

    return (
        <>
            <NavBar 
                language={language}
                handleLanguage={handleLanguage}
                selectedPage={selectedPage} 
                handleSelectedPage={handleSelectedPage}
            />
            {/*<h1>Services</h1>*/}
            <div className="services-container">
                <ServicesSection 
                    language={language}
                    services={services} 
                    selectedPage={selectedPage} 
                    handleSelectedPage={handleSelectedPage} 
                    handleMailSubjectChange={handleMailSubjectChange}
                />
            </div>
            
            <Footer />
        </>
    );
}

export default Services;