import React, {FC, ReactElement, useEffect, useState} from "react";

import { useInView } from "react-intersection-observer";
import { ServiceDetails } from "../../App";
import Button from "../Button/button";
import "./servicesScroll.css"
import { Link } from "react-router-dom";

interface ServicesScrollProps{
    language: string;
    services: ServiceDetails[];
    selectedPage: string; 
    handleSelectedPage: (value: string) => void
    handleMailSubjectChange: (value: string) => void;
}

const ServicesScroll: FC<ServicesScrollProps> = ({ language, services, selectedPage, handleSelectedPage, handleMailSubjectChange }) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const handleMouseHover = (index: number) => {
        if (index >= 4) {
            setHoveredIndex(index);
        }
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
        

    const [ref0, inView0] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref1, inView1] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref2, inView2] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref3, inView3] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref4, inView4] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref5, inView5] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref6, inView6] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref7, inView7] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref8, inView8] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref9, inView9] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref10, inView10] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref11, inView11] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });
    const [ref12, inView12] = useInView({
        triggerOnce: true, // A animação ocorrerá apenas uma vez
    });

    return (

        language === "EN" ? (
            <div className="services-scroll-container">
                {windowWidth > 900 ? (
                    services
                        .filter((service) => service.id !== 5 && service.id !== 6 && service.id !== 9)
                        .map((service, index) => (
                            <>
                                {index === 4 && (
                                    <p 
                                        style={{fontSize: "3rem", fontWeight: "bold", margin: "50px 0 -10px 0"}}
                                    >
                                        Support Provided By Us
                                    </p>
                                )}
                                <div 
                                    key={index} 
                                    className={eval(`inView${index}`) ? `${index >= 4 ? `animate-expand${index}` : "service-box animateFade"}` : "service-box"} 
                                    ref={eval(`ref${index}`)}
                                    onMouseEnter={() => handleMouseHover(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {index < 4 || index === hoveredIndex ? (
                                        <>
                                            <h1 className="service-box-title">{service.serviceTitleEN}</h1>
                                            <div className="service-box-footer">
                                                <p className="text-shadow">{service.serviceDescriptionEN}</p>
                                                <div className="service-box-footer-btns">
                                                <button onClick={() => handleSelectedPage("Services")}>
                                                    <Link to="/services" style={{ textDecoration: "none", color: "white" }}>
                                                        Learn More
                                                    </Link>
                                                </button>
                                                <Button
                                                    text={"Buy Service"}
                                                    selectedPage={"Contact"}
                                                    handleSelectedPage={handleSelectedPage}
                                                    subject={service.serviceTitleEN}
                                                    handleMailSubjectChange={handleMailSubjectChange}
                                                />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <h1 className="service-box-title">{service.serviceTitleEN}</h1>
                                    )}

                                </div>
                            </>
                    ))
                ) : (
                    services
                        .map((service, index) => (
                            <div 
                                key={index} 
                                className={eval(`inView${index}`) ? "service-box animateFade" : "service-box"} 
                                ref={eval(`ref${index}`)}
                            >
                                <h1 className="service-box-title">{service.serviceTitleEN}</h1>
                                <div className="service-box-footer">
                                    <p className="text-shadow">{service.serviceDescriptionEN}</p>
                                    <div className="service-box-footer-btns">
                                    <button onClick={() => handleSelectedPage("Services")}>
                                        <Link to="/services" style={{ textDecoration: "none", color: "white" }}>
                                            Learn More
                                        </Link>
                                    </button>
                                    <Button
                                        text={"Buy Service"}
                                        selectedPage={"Contact"}
                                        handleSelectedPage={handleSelectedPage}
                                        subject={service.serviceTitleEN}
                                        handleMailSubjectChange={handleMailSubjectChange}
                                    />
                                    </div>
                                </div>
                            </div>
                    ))
                )}
                
            </div>
        ) : (
            <div className="services-scroll-container">
                {windowWidth > 900 ? (
                    services
                        .filter((service) => service.id !== 5 && service.id !== 6 && service.id !== 9)
                        .map((service, index) => (
                            <>
                                {index === 4 && (
                                    <p 
                                        style={{fontSize: "3rem", fontWeight: "bold", margin: "50px 0 -10px 0"}}
                                    >
                                        Suporte Fornecido por Nós
                                    </p>
                                )}
                                <div 
                                    key={index} 
                                    className={eval(`inView${index}`) ? `${index >= 4 ? `animate-expand${index}` : "service-box animateFade"}` : "service-box"} 
                                    ref={eval(`ref${index}`)}
                                    onMouseEnter={() => handleMouseHover(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {index < 4 || index === hoveredIndex ? (
                                        <>
                                            <h1 className="service-box-title">{service.serviceTitle}</h1>
                                            <div className="service-box-footer">
                                                <p className="text-shadow">{service.serviceDescription}</p>
                                                <div className="service-box-footer-btns">
                                                <button onClick={() => handleSelectedPage("Services")}>
                                                    <Link to="/services" style={{ textDecoration: "none", color: "white" }}>
                                                        Saiba Mais
                                                    </Link>
                                                </button>
                                                <Button
                                                    text={"Buy Service"}
                                                    selectedPage={"Contact"}
                                                    handleSelectedPage={handleSelectedPage}
                                                    subject={service.serviceTitle}
                                                    handleMailSubjectChange={handleMailSubjectChange}
                                                />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <h1 className="service-box-title">{service.serviceTitle}</h1>
                                    )}

                                </div>
                            </>
                    ))
                ) : (
                    services
                        .map((service, index) => (
                            <div 
                                key={index} 
                                className={eval(`inView${index}`) ? "service-box animateFade" : "service-box"} 
                                ref={eval(`ref${index}`)}
                            >
                                <h1 className="service-box-title">{service.serviceTitle}</h1>
                                <div className="service-box-footer">
                                    <p className="text-shadow">{service.serviceDescription}</p>
                                    <div className="service-box-footer-btns">
                                    <button onClick={() => handleSelectedPage("Services")}>
                                        <Link to="/services" style={{ textDecoration: "none", color: "white" }}>
                                            Saiba Mais
                                        </Link>
                                    </button>
                                    <Button
                                        text={"Buy Service"}
                                        selectedPage={"Contact"}
                                        handleSelectedPage={handleSelectedPage}
                                        subject={service.serviceTitle}
                                        handleMailSubjectChange={handleMailSubjectChange}
                                    />
                                    </div>
                                </div>
                            </div>
                    ))
                )}
            </div>
        )

    );
}

export default ServicesScroll;