import React, {FC, ReactElement, useEffect, useState} from "react";

import "./category.css"

interface CategoryProps{
    name: string;
    selectedCategory: string;
    handleSelectedCategory: (category: string) => void;
    categoriesEN: string[];
    categoriesPT: string[];
}

const Category: FC<CategoryProps> = ({name, selectedCategory, handleSelectedCategory, categoriesEN, categoriesPT}) => {

    
    const setCategoryToEN = (name: string) => {
        if(categoriesEN.includes(name)){
            return name;
        }else{
            return categoriesEN[categoriesPT.indexOf(name)];
        }
    };
    const isSelected = selectedCategory === setCategoryToEN(name);
    const className = isSelected ? "category selected-category" : "category";
    
    useEffect(() => {
        console.log(name);
        console.log(selectedCategory);
    }, [handleSelectedCategory]);

    return (
        <div className={className} onClick={() => handleSelectedCategory(setCategoryToEN(name))}>
            {name}
        </div>
    );
}

export default Category;